import Controller from './controller'

import type { Character } from '../type'

export default class CharactersController extends Controller {
  // List characters
  // GET /api/games/:gameId/characters
  async index(gameId: string): Promise<Character[]> {
    const response = await this.instance.get(`/games/${gameId}/characters`)
    return response.data.characters as Character[]
  }
}

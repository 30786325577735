import Controller from './controller'

import type { Chat } from '../type'

export default class ChatsController extends Controller {
  // List chats
  async index(gameId: string): Promise<Chat[]> {
    const response = await this.instance.get(`/games/${gameId}/chats`)
    return response.data.chats as Chat[]
  }

  // Get chat
  // GET /api/games/:game_id/chats/:chat_id
  async show(gameId: string, chatId: string): Promise<Chat> {
    const response = await this.instance.get(`/games/${gameId}/chats/${chatId}`)
    return response.data.chat as Chat
  }

  // Update chat line
  // PUT /api/games/:game_id/chats/:chat_id/lines/:line_id
  async put(gameId: string, chatId: string, lineId: string, line: unknown = {}): Promise<Chat> {
    const response = await this.instance.put(`/games/${gameId}/chats/${chatId}/lines/${lineId}`, {
      line
    })
    return response.data.chat as Chat
  }

  // Set line read
  async putRead(gameId: string, chatId: string, lineId: string) {
    return await this.put(gameId, chatId, lineId, { read: true })
  }

  // Set line action
  async putAction(
    gameId: string,
    chatId: string,
    lineId: string,
    action: string,
    params: unknown = {}
  ) {
    return await this.put(gameId, chatId, lineId, { action, params })
  }
}

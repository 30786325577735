import axios from 'axios'
import qs from 'qs'
import { v4 as uuidv4 } from 'uuid'

import {
  ArchivesController,
  CharactersController,
  ChatsController,
  EventsController,
  GamesController,
  SessionsController,
  UsersController,
  QuestsController
} from './controllers'

import type { Error, Ticket } from './type'
import type GameChannel from './channels/game_channel'
import { useUserStore } from '@/stores/user'

// create static instance of GameChannel
let gameChannel: GameChannel | null = null

const authenticate = () => {
  window.location.href = '/auth?auth[redirect]=' + encodeURIComponent(`${window.location.href}`)
}

const instance = axios.create({
  baseURL: '/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

instance.interceptors.request.use((config) => {
  const { locale } = useUserStore()

  config.paramsSerializer = {
    serialize: (params) => {
      return qs.stringify(params, { arrayFormat: 'brackets', encode: true })
    }
  }

  config.params = {
    ...config.params,
    locale: locale
  }
  return config
})

instance.interceptors.request.use((config) => {
  config.headers['X-Transaction-ID'] = uuidv4()
  return config
})

instance.interceptors.response.use(
  async (response) => {
    if (response.status === 202) {
      // Accepted

      if (gameChannel && response.data.ticket) {
        const ticket = response.data.ticket as Ticket
        response.data = await gameChannel.poll(ticket.id)
      }
    }

    return response
  },
  (error) => {
    if (error.response?.status === 401) {
      const pathname = window.location.pathname
      if (pathname !== '/auth') {
        authenticate()
      }
    }

    if (error.response?.data?.error) {
      const err = error.response?.data?.error as Error
      return Promise.reject(new Error(err.message))
    }

    return Promise.reject(error)
  }
)

// Create controllers
const archivesController = new ArchivesController(instance)
const chatsController = new ChatsController(instance)
const charactersController = new CharactersController(instance)
const eventsController = new EventsController(instance)
const gamesController = new GamesController(instance)
const questsController = new QuestsController(instance)
const sessionsController = new SessionsController(instance)
const usersController = new UsersController(instance)

export default {
  setGameChannel(channel: GameChannel | null) {
    gameChannel = channel
  },
  authenticate,

  archives: archivesController,
  chats: chatsController,
  characters: charactersController,
  events: eventsController,
  games: gamesController,
  quests: questsController,
  sessions: sessionsController,
  users: usersController
}

<template lang="pug">
t-layout
  t-header.header
    t-head-menu(v-model="path", @change="onMenuChanged")
      template(#logo)
        img.logo-banner(height="36", src="/images/logo_banner.png", alt="logo")
      t-menu-item(value="/")
        | Home
      t-menu-item(value="/games")
        | Games
      t-menu-item(value="/about")
        | About
      template(#operations)
        t-select(v-model="selectedLocale")
          t-option(key="en", label="English", value="en")
          t-option(key="zh_CN", label="简体中文", value="zh_CN")
        t-button(v-if="!userStore.loggedIn", variant="text", style="flex-shrink: 0", @click="onLogin")
          | {{ t('header.login') }}
        t-button(v-if="userStore.loggedIn", variant="text", @click="onLogout")
          template(#icon)
            t-icon(name="user")
          | Logout ({{ userStore.user?.email }})
  t-layout.content
    router-view(v-slot="{ Component }")
      transition(mode="out-in", name="fade")
        component(:is="Component")
  t-footer
    t-divider.footer
      | YetAnother.AI © 2023
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { onMounted, ref, watch } from 'vue'

import { useRoute, useRouter } from 'vue-router'

import { useUserStore } from '@/stores/user'

import api from '@/api/api'

const route = useRoute()
const router = useRouter()
const path = ref('')

const userStore = useUserStore()

// i18n
const { t, locale } = useI18n()
const supportLocales = ['en', 'zh_CN']
const selectedLocale = ref('')
watch([selectedLocale], ([newVal]) => {
  locale.value = newVal
  userStore.locale = newVal
  localStorage.setItem('lang', newVal)
})

watch(
  () => route.path,
  () => {
    path.value = route.path
  }
)

const onMenuChanged = (path: string) => {
  router.push(path)
}

const onLogin = () => {
  api.authenticate()
}

const onLogout = () => {
  userStore.logout()
  router.push('/')
}

function getLocale(lang: string): string {
  lang = lang.replaceAll('-', '_')
  if (supportLocales.includes(lang)) return lang

  // fallback
  return 'en'
}

onMounted(() => {
  userStore.fetch()
  path.value = route.path
  // initial app language
  const lang = localStorage.getItem('lang')
  if (!lang) {
    selectedLocale.value = getLocale(navigator.language)
    return
  }
  selectedLocale.value = getLocale(lang)
})
</script>

<style lang="stylus">
body
  margin 0
  --td-brand-color #ff8438
  background var(--td-bg-color-page)

.header
  border-bottom #202020 solid 1px

.logo-banner
  margin-left 32px

.content
  margin 20px
  min-height calc(100vh - 200px)

.footer
  font-family monospace

.fade-enter-active, .fade-leave-active
  transition-property opacity
  transition-duration .2s

.fade-enter-active
  transition-delay .2s

.fade-enter, .fade-leave-active
  opacity: 0
</style>

import { computed, ref, type Ref } from 'vue'
import { defineStore } from 'pinia'

import api from '@/api/api'
import type { User } from '@/api/type'

export const useUserStore = defineStore('user', () => {
  const locale = ref('en')
  const user: Ref<User | null> = ref(null)
  const loggedIn = computed(() => !(user.value === null))

  async function fetch() {
    user.value = await api.sessions.index()
  }

  async function logout() {
    await api.sessions.destroy()
    user.value = null
  }

  return { user, loggedIn, fetch, logout, locale }
})

import Controller from './controller'

import type { Quest } from '../type'

export default class QuestsController extends Controller {
  // List quests
  // GET /api/games/:gameId/quests
  async index(gameId: string): Promise<Quest[]> {
    const response = await this.instance.get(`/games/${gameId}/quests`)
    return response.data.quests as Quest[]
  }
}

import Controller from './controller'

import type { User } from '../type'

export default class SessionsController extends Controller {
  // Login
  // Check if logged in
  // GET /api/sessions
  async index(): Promise<User> {
    const response = await this.instance.get('/sessions')
    return response.data.user as User
  }

  // Logout
  // DELETE /api/sessions
  async destroy() {
    await this.instance.delete('/sessions')
  }
}

import Controller from './controller'

import type { Game } from '../type'

export default class GamesController extends Controller {
  // Create game
  // POST /api/games
  async create(): Promise<Game> {
    const response = await this.instance.post('/games')
    return response.data.game as Game
  }

  // Get games
  // GET /api/games
  async index(): Promise<Game[]> {
    const response = await this.instance.get('/games')
    return response.data.games as Game[]
  }

  // Get game
  // GET /api/games/:game_id
  async show(gameId: string): Promise<Game> {
    const response = await this.instance.get(`/games/${gameId}`)
    return response.data.game as Game
  }
}

import Controller from './controller'

import type { User } from '../type'

export default class UsersController extends Controller {
  // Confirm email
  // PATCH /api/users/:id/email/confirm
  async confirmEmail(userId: number, token: string): Promise<User> {
    const response = await this.instance.patch(`/users/${userId}/email/confirm`, { token })
    return response.data.user as User
  }

  // Send mail for confirmation
  // POST /api/users/:id/email/send_for_confirmation
  async sendMailForConfirmation(userId: number): Promise<User> {
    const response = await this.instance.post(`/users/${userId}/email/send_for_confirmation`)
    return response.data.user as User
  }

  // Send mail for password reset
  // POST /api/users/send_password_reset_email
  async sendMailForPasswordReset(email: string): Promise<void> {
    await this.instance.post(`/users/send_password_reset_email`, { email })
  }

  // Update user profile
  // PUT /api/users/:id
  async update(user: Partial<User>): Promise<User> {
    const response = await this.instance.put(`/users/${user.id}`, user)
    return response.data.user as User
  }

  // Verify token in email
  // POST /api/users/:id/verify_email_token
  async verifyEmailToken(id: number, token: string): Promise<User> {
    const response = await this.instance.post(`/users/${id}/verify_email_token`, { token })
    return response.data.user as User
  }
}

<template lang="pug">
t-layout
  t-content
    h2 Create a New Game
    t-button(@click="onCreateGame")
      | Create
    h2 Saved Games
    t-card.card(v-for="game in games", :key="game.id", @click="onLoadGame(game.id)")
      | ID: {{ game.id }}
      br
      | Created at {{ formatDate(game.created_at) }}
      br
      | Updated at {{ formatDate(game.updated_at) }}
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import moment from 'moment'

import api from '@/api/api'
import type { Game } from '@/api/type'

const router = useRouter()

const games = ref<Game[]>([])

const loadData = async () => {
  const resp = await api.games.index()
  games.value = resp
}

const onCreateGame = async () => {
  const resp = await api.games.create()
  router.push(`/games/${resp.id}`)
}

const onLoadGame = (id: number) => {
  router.push(`/games/${id}`)
}

const formatDate = (date: string) => {
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

onMounted(() => {
  loadData()
})
</script>

<style lang="stylus" scoped>
.card
  margin 20px 0
  cursor pointer
</style>

import Controller from './controller'

import type { Archive } from '../type'

export default class ArchivesController extends Controller {
  // List archives
  // GET /api/games/:gameId/archives
  async index(gameId: string): Promise<Archive[]> {
    const response = await this.instance.get(`/games/${gameId}/archives`)
    return response.data.archives as Archive[]
  }
}

import Controller from './controller'

import type { Event, Pagination } from '../type'

export default class EventsController extends Controller {
  // List events
  // GET /api/games/:gameId/events
  async index(
    gameId: string,
    archiveIds: number[],
    characterId: number | null,
    keyword: string,
    pagination: Pagination
  ): Promise<[Event[], Pagination]> {
    const response = await this.instance.get(`/games/${gameId}/events`, {
      params: {
        events_search: {
          archive_ids: archiveIds,
          character_id: characterId,
          keyword
        },
        pagination: {
          page: pagination.current,
          per: pagination.per_page
        }
      }
    })
    return [response.data.events as Event[], response.data.pagination as Pagination]
  }
}
